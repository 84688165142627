import React, { FC } from 'react'
import styles from './DefaultAvatar.module.scss'

import Waves from 'svgs/property-plan/waves-2'
import BlankPhoto from 'svgs/blank-photo'

import cx from 'classnames'

interface DefaultAvatarProps {
  className?: string
}

export const DefaultAvatar: FC<DefaultAvatarProps> = ({ className }) => (
  <div className={cx(styles.defaultAvatar, className)}>
    <Waves className={styles.background} />
    <BlankPhoto className={styles.icon} />
  </div>
)

export default DefaultAvatar
