import React, { FC } from 'react'
import classNames from 'classnames'

import ImgSparkles from 'svgs/property-plan/sparkles'

import styles from './Sparkle.module.scss'

interface Props {
  className?: string
}

export const Sparkle: FC<Props> = ({ className }) => {
  return (
    <ImgSparkles
      className={classNames(styles.sparkle, className)}
      star1ClassName={styles.star1}
      star2ClassName={styles.star2}
      star3ClassName={styles.star3}
    />
  )
}

export default Sparkle
