import React, { forwardRef, ForwardRefRenderFunction, ReactNode, useCallback } from 'react'
import cx from 'classnames'

import styles from './Input.module.scss'

export interface InputProps {
  autoComplete?: string
  autoFocus?: boolean
  id?: string
  value?: string
  onChange?: (value: string) => void
  onRawChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  placeholder?: string
  className?: string
  errors?: string[]
  name?: string
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
  helperText?: ReactNode
  readOnly?: boolean
  required?: boolean
  suffix?: ReactNode
  tag?: 'input' | 'textarea' // keyof JSX.IntrinsicElements
  transparent?: boolean
  type?: string
  label?: string
  list?: string
  optional?: boolean
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  showOptionalLabel?: boolean
  step?: string
  tooltip?: ReactNode
  disabled?: boolean
}

export { styles }

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    autoComplete,
    autoFocus,
    className,
    disabled,
    errors,
    helperText,
    id,
    label,
    name,
    onChange,
    onRawChange,
    onFocus,
    onBlur,
    onKeyDown,
    inputMode,
    placeholder,
    readOnly,
    required,
    suffix,
    tag: WrapperTag = 'input',
    type = 'text',
    value,
    list,
    optional,
    min,
    max,
    minLength,
    maxLength,
    showOptionalLabel = true,
    step,
    tooltip,
  },
  ref
) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onRawChange && onRawChange(e)
      onChange && onChange(e.target.value)
    },
    [onRawChange, onChange]
  )

  const wt = (
    <WrapperTag
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      ref={ref as any}
      id={id}
      type={type}
      value={value}
      name={name}
      inputMode={inputMode}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      min={min}
      max={max}
      minLength={minLength}
      maxLength={maxLength}
      step={step}
      className={cx('form-control tw-grow', { 'is-invalid': errors && errors.length })}
      list={list}
    />
  )

  const wtAndSuffix = suffix ? (
    <div className={cx('tw-flex tw-items-center', { 'is-invalid': errors && errors.length })}>
      {wt}
      {suffix}
    </div>
  ) : (
    wt
  )

  return (
    <div className={cx(className, styles.input)}>
      {label && (
        <label className={'tw-mb-2 tw-font-medium tw-text-base-charcoal'} htmlFor={id}>
          {label}
          {optional && showOptionalLabel && <span className="tw-ml-2 tw-text-gray-500">{'(Optional)'}</span>}
        </label>
      )}
      {tooltip}
      {errors && errors.length ? (
        <>
          {wtAndSuffix}
          <div className="invalid-feedback">
            <ul>
              {(errors || []).map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        wtAndSuffix
      )}
      {helperText}
    </div>
  )
}

export default forwardRef(Input)
