import React, { FC, useState, useEffect, useRef, useCallback, ForwardRefRenderFunction, forwardRef } from 'react'

import Input, { InputProps } from './Input'

const GOOGLE_MAPS_API_SCRIPT_ID = 'googleMapsApiScript'
const MAX_PREDICTION_INTERVAL = 500 // milliseconds

interface PredictionOptionProps {
  prediction: Prediction
  onClick: (prediction: Prediction) => void
  renderDescription?: (prediction: Prediction) => React.ReactNode
}

const PredictionOption: FC<PredictionOptionProps> = ({ onClick, prediction, renderDescription }) => {
  const handleClick = useCallback(() => {
    onClick(prediction)
  }, [onClick, prediction])

  const onItemKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 13) handleClick()
    },
    [handleClick]
  )

  return (
    <li>
      <div onClick={handleClick} role="button" tabIndex={-1} onKeyPress={onItemKeyPress}>
        {renderDescription ? renderDescription(prediction) : prediction.description}
      </div>
    </li>
  )
}

export interface Prediction {
  description: string
  placeId: string
  mainText?: string
  secondaryText?: string
}

interface FullAddressInputProps extends Omit<InputProps, 'autoComplete'> {
  // Called when one of the predictions is clicked
  onPredictionSelected: (prediction: Prediction | null) => void
  renderSubmitButton?: (onClick: (e: any) => void, disabled: boolean) => React.ReactNode
  renderDescription?: (prediction: Prediction) => React.ReactNode
  renderIcon?: () => React.ReactNode
}

const FullAddressInput: ForwardRefRenderFunction<HTMLInputElement, FullAddressInputProps> = (
  {
    value,
    onChange,
    onPredictionSelected,
    renderSubmitButton,
    renderDescription,
    renderIcon,
    onFocus,
    onBlur,
    ...otherProps
  },
  ref
) => {
  const [autocomplete, setAutocomplete] = useState<any>(null)
  const [predictions, setPredictions] = useState<Array<Prediction>>([])
  const [showPredictions, setShowPredictions] = useState(false)
  const nextPrediction = useRef(0)
  const predictionRequestQueued = useRef('')

  // We need to check for the presence of the Google Maps API, but because this
  // component can be server-rendered, first check for the existince of the window object
  let w
  if (typeof window !== 'undefined') {
    w = window as any
  }
  const placesApi = w?.google?.maps?.places

  // Google Maps API Script warmup,
  // Initialize the service
  useEffect(() => {
    let isDone = false
    const initializeService = () => {
      // Initialize the service.
      const newAutocomplete = new w.google.maps.places.AutocompleteService()
      isDone || setAutocomplete(newAutocomplete)
    }

    // Inject the script if it doesn't exist
    if (document.getElementById(GOOGLE_MAPS_API_SCRIPT_ID) == null) {
      const injectedScript = document.createElement('script')
      injectedScript.id = GOOGLE_MAPS_API_SCRIPT_ID
      const key = document.body.dataset.googleMapsApiKey
      injectedScript.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`
      injectedScript.defer = true

      document.body.appendChild(injectedScript)

      injectedScript.onload = initializeService
    } else if (placesApi) {
      // Initialize the service if the script has already loaded
      initializeService()
    }
    return () => {
      isDone = true
    }
  }, [w, placesApi])

  useEffect(() => {
    // Make sure we have what we need.
    if (!autocomplete || !value) {
      setPredictions([])
      return
    }

    let delayTimer: any

    const issueNextPrediction = () => {
      clearTimeout(delayTimer)
      delayTimer = null
      nextPrediction.current = 0

      // If we had another prediction request waiting, issue that.
      if (predictionRequestQueued.current) {
        const newV = predictionRequestQueued.current
        predictionRequestQueued.current = ''
        issuePredictionRequest(newV)
      }
    }

    const issuePredictionRequest = (v) => {
      nextPrediction.current = Date.now() + MAX_PREDICTION_INTERVAL
      // Begin a search
      autocomplete.getPlacePredictions(
        {
          input: v,
          types: ['address'],
          componentRestrictions: { country: 'us' },
        },
        (predictions: Array<any> | null) => {
          const results = (predictions || []).map((p) => ({
            description: p.description,
            mainText: p.structured_formatting?.main_text,
            secondaryText: p.structured_formatting?.secondary_text,
            placeId: p.place_id,
          }))
          setPredictions(results)

          // We're 'done' with this prediction after our delay.
          delayTimer = setTimeout(issueNextPrediction, MAX_PREDICTION_INTERVAL)
        }
      )
    }

    // Only one prediction at a time please!
    const delay = nextPrediction.current - Date.now()
    if (delay > 0) {
      predictionRequestQueued.current = value
      delayTimer = setTimeout(issueNextPrediction, delay)
    } else {
      issuePredictionRequest(value)
    }
    return () => {
      if (delayTimer) clearTimeout(delayTimer)
    }
  }, [autocomplete, value])

  const handleChange = useCallback(
    (value: string) => {
      onPredictionSelected(null)
      onChange && onChange(value)
    },
    [onChange, onPredictionSelected]
  )

  const handleBlur = useCallback(
    (e) => {
      window.setTimeout(() => {
        setShowPredictions(false)
        if (onBlur) onBlur(e)
      }, 250)
    },
    [onBlur]
  )

  const handleFocus = useCallback(
    (event) => {
      setShowPredictions(true)
      if (onFocus) onFocus(event)
    },
    [onFocus]
  )

  const handleSubmitClick = useCallback(() => {
    if (predictions[0] != null) {
      onPredictionSelected(predictions[0])
    }
  }, [onPredictionSelected, predictions])

  return (
    <>
      <Input
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        helperText={
          showPredictions &&
          predictions.length > 0 && (
            <ol>
              {predictions.map((prediction) => (
                <PredictionOption
                  key={prediction.description}
                  prediction={prediction}
                  onClick={onPredictionSelected}
                  renderDescription={renderDescription}
                />
              ))}
            </ol>
          )
        }
        {...otherProps}
        ref={ref}
        autoComplete="off"
      />
      {renderIcon && renderIcon()}
      {renderSubmitButton &&
        renderSubmitButton(
          handleSubmitClick,
          (value && value.length > 0 && predictions.length < 1) || !!otherProps.readOnly
        )}
    </>
  )
}

export default forwardRef(FullAddressInput)
