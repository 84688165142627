export async function poll<T>({
  fn,
  validate,
  interval,
  maxAttempts,
}: {
  fn: () => Promise<T>
  validate: (pollResponse: T) => boolean
  interval: number
  maxAttempts: number
}): Promise<T> {
  let attempts = 0

  const executePoll = async (resolve, reject) => {
    const pollResponse = await fn()
    attempts++

    if (validate(pollResponse)) {
      return resolve(pollResponse)
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'))
    } else {
      setTimeout(executePoll, interval, resolve, reject)
    }
  }

  return new Promise(executePoll)
}
