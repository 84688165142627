import React, { FC } from 'react'

interface Props {
  className?: string
  star1ClassName?: string
  star2ClassName?: string
  star3ClassName?: string
}

const Svg: FC<Props> = ({ className, star1ClassName, star2ClassName, star3ClassName }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="64px" height="71px" viewBox="0 0 64 71">
    <g>
      <g fill="currentColor" fillRule="nonzero">
        <path
          className={star1ClassName}
          d="M20.7958079,18.7248908 L20.2089083,19.3106725 L20.1977293,23.6973275 C20.1977293,36.0048908 17.28,38.9343581 4.98026201,38.9343581 L0.586899563,38.9343581 L0,39.5179039 L0,40.4122271 L0.586899563,40.9974498 L4.97187773,41.0097467 C17.28,41.0097467 20.2089083,43.926917 20.2089083,56.2277729 L20.2089083,60.6211354 L20.7958079,61.206917 L21.6873362,61.206917 L22.2742358,60.6211354 L22.2854148,56.2344803 C22.2854148,43.926917 25.2031441,40.9974498 37.5028821,40.9974498 L41.8962445,40.9974498 L42.4803493,40.4122271 L42.4803493,39.5179039 L41.8934498,38.9326812 L37.5084716,38.9203843 C25.2003493,38.9203843 22.271441,36.003214 22.271441,23.7023581 L22.271441,19.3089956 L21.6873362,18.7248908 L20.7958079,18.7248908 Z"
        ></path>
        <path
          className={star2ClassName}
          d="M46.7954585,0 L46.4041921,0.391266376 L46.3958079,3.30620087 C46.3958079,11.484786 44.4590393,13.4304978 36.2843668,13.4304978 L33.3694323,13.4304978 L32.9781659,13.8217642 L32.9781659,14.4131354 L33.3694323,14.8044017 L36.2843668,14.8116681 C44.4646288,14.8116681 46.4097817,16.7501135 46.4097817,24.9242271 L46.4097817,27.8358079 L46.801048,28.2270742 L47.3935371,28.2270742 L47.7848035,27.8358079 L47.7931878,24.9208734 C47.7931878,16.7422882 49.7299563,14.7965764 57.9046288,14.7965764 L60.8251528,14.7965764 L61.2164192,14.40531 L61.2164192,13.8178515 L60.8251528,13.4265852 L57.9102183,13.4193188 C49.7299563,13.4193188 47.7848035,11.4808734 47.7848035,3.30675983 L47.7848035,0.391266376 L47.3935371,0 L46.7954585,0 Z"
        ></path>
        <path
          className={star3ClassName}
          d="M53.0137991,49.1279651 L52.7175546,49.4247686 L52.7119651,51.6471616 C52.7119651,57.8817118 51.2335371,59.3657293 45.0040175,59.3657293 L42.7765939,59.3657293 L42.4803493,59.6625328 L42.4803493,60.113607 L42.7765939,60.4104105 L44.9984279,60.416 C51.2335371,60.416 52.7175546,61.893869 52.7175546,68.1256245 L52.7175546,70.3508122 L53.0137991,70.6476157 L53.4665502,70.6476157 L53.7627948,70.3508122 L53.7683843,68.1289782 C53.7683843,61.893869 55.2468122,60.4104105 61.4763319,60.4104105 L63.7037555,60.4104105 L64,60.113607 L64,59.6625328 L63.7037555,59.3657293 L61.4847162,59.3606987 C55.249607,59.3606987 53.7655895,57.8828297 53.7655895,51.6516332 L53.7655895,49.4247686 L53.469345,49.1279651 L53.0137991,49.1279651 Z"
        ></path>
      </g>
    </g>
  </svg>
)

export default Svg
