import React, { FC } from 'react'
import cx from 'classnames'

import Spinner from 'components/Spinner'

import styles from './Loading.module.scss'

interface Props {
  className?: string
}

const Loading: FC<Props> = ({ className }) => {
  return (
    <div className={cx(styles.loading, className)}>
      <Spinner size="xl" />
    </div>
  )
}

export default Loading
