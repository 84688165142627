import React, { FC, ReactNode, createContext, useContext, useRef, useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

import styles from './styles.module.scss'

interface Context {
  root: HTMLDivElement | null
}

const OverlayContext = createContext<Context>({
  root: null,
})

/**
 * Return a 'mount' function that can be used to mount content
 * into the overlay.
 */
export function useOverlay(): {
  mount: (node: ReactNode) => ReactNode
} {
  const context = useContext(OverlayContext)

  // Create a div to have the requesting component's content isolated to.
  const mountDiv = useRef<HTMLElement | null>(null)

  // Ensure mountDiv is set if available
  if (!mountDiv.current) {
    mountDiv.current = document.createElement('div')
  }

  // Add on component create, remove on destroy.
  useEffect(() => {
    const root = context.root
    if (root && mountDiv.current) root.appendChild(mountDiv.current)

    return () => {
      if (root && mountDiv.current) root.removeChild(mountDiv.current)
    }
  }, [context.root])

  if (!mountDiv.current) throw 'No overview mount point exists!'

  // Return a mounting function
  return {
    mount: (node) => mountDiv.current && createPortal(node, mountDiv.current),
  }
}

const OverlayMount: FC<{ children?: ReactNode }> = ({ children }) => {
  const root = useRef<HTMLDivElement | null>(null)
  // useRef won't cause a re-render when the ref is updated,
  // So we'll use state to force a render once it is set.
  // That way our context is properly updated.
  const [rootReady, setRootReady] = useState(false)

  // Use a callback Ref so we can update our state
  // when our ref is updated.
  const setRoot = useCallback(
    (newRoot: HTMLDivElement) => {
      root.current = newRoot
      setRootReady(true)
    },
    [root]
  )

  return (
    <OverlayContext.Provider value={{ root: rootReady ? root.current : null }}>
      {children}
      <div className={styles.overlay} ref={setRoot}></div>
    </OverlayContext.Provider>
  )
}

export default OverlayMount
