import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="1281px" height="825px" viewBox="0 0 1281 825">
    <g>
      <g
        fill="none"
        fillRule="evenodd"
        strokeWidth="1.2"
        stroke="currentColor"
        transform="translate(1.000000, 1.000000)"
      >
        <path d="M472.277664,72.5455288 C472.277664,72.5455288 547.22744,0.0522662311 666.708044,0.0522662311 C768.104532,0.0522662311 837.61862,34.8615762 862.706411,102.650878 C887.219273,168.819927 919.624336,230.285014 1039.26174,200.38873 C1189.10902,162.966109 1279.58187,214.971009 1279.58187,214.971009"></path>
        <path d="M449.541854,101.23969 C449.541854,101.23969 524.491629,28.7464271 643.972234,28.7464271 C745.368722,28.7464271 814.882809,63.555737 839.9706,131.345039 C864.431196,197.514087 896.83626,258.979175 1016.52593,229.082891 C1166.37321,191.660269 1256.84606,243.665169 1256.84606,243.665169"></path>
        <path d="M426.753777,129.986117 C426.753777,129.986117 501.703552,57.4928542 621.184157,57.4928542 C722.580645,57.4928542 792.094733,92.3021641 817.182523,160.091466 C841.64312,226.260514 874.048183,287.725602 993.737852,257.829318 C1143.58514,220.406697 1234.05798,272.411597 1234.05798,272.411597"></path>
        <path d="M404.017967,158.680278 C404.017967,158.680278 478.967742,86.1870151 598.448346,86.1870151 C699.844835,86.1870151 769.358922,120.996325 794.446713,188.785627 C818.907309,254.954675 851.312372,316.419763 971.002042,286.523479 C1120.84933,249.100857 1211.32217,301.105757 1211.32217,301.105757"></path>
        <path d="M381.22989,187.426705 C381.22989,187.426705 456.179665,114.933442 575.660269,114.933442 C677.056758,114.933442 746.570845,149.742752 771.658636,217.532054 C796.119232,283.701102 828.524296,345.16619 948.213965,315.269906 C1098.06125,277.847285 1188.5341,329.852185 1188.5341,329.852185"></path>
        <path d="M358.441813,216.120866 C358.441813,216.120866 433.391588,143.627603 552.872193,143.627603 C654.268681,143.627603 723.782768,178.436913 748.870559,246.226215 C773.331156,312.395263 805.736219,373.860351 925.425888,343.964067 C1075.27317,306.541445 1165.74602,358.546345 1165.74602,358.546345"></path>
        <path d="M335.706002,244.867293 C335.706002,244.867293 410.655778,172.37403 530.136382,172.37403 C631.532871,172.37403 701.046958,207.18334 726.134749,274.972642 C750.595345,341.14169 783.000408,402.606778 902.690078,372.710494 C1052.53736,335.287873 1143.01021,387.292773 1143.01021,387.292773"></path>
        <path d="M312.917926,273.561454 C312.917926,273.561454 387.867701,201.068191 507.348305,201.068191 C608.744794,201.068191 678.258881,235.877501 703.346672,303.666803 C727.807268,369.835851 760.212332,431.300939 879.902001,401.404655 C1029.74929,363.982033 1120.22213,415.986933 1120.22213,415.986933"></path>
        <path d="M290.182115,302.307881 C290.182115,302.307881 365.131891,229.814618 484.612495,229.814618 C586.008983,229.814618 655.523071,264.623928 680.610862,332.41323 C705.071458,398.634545 737.476521,460.047366 857.16619,430.151082 C1007.01347,392.728461 1097.48632,444.733361 1097.48632,444.733361"></path>
        <path d="M267.394038,331.002042 C267.394038,331.002042 342.343814,258.508779 461.824418,258.508779 C563.220906,258.508779 632.734994,293.318089 657.822785,361.107391 C682.283381,427.276439 714.688444,488.741527 834.378114,458.845243 C984.225398,421.422621 1074.69824,473.427521 1074.69824,473.427521"></path>
        <path d="M244.658228,359.748469 C244.658228,359.748469 319.608003,287.255206 439.088608,287.255206 C540.485096,287.255206 609.999183,322.064516 635.086974,389.853818 C659.54757,456.022866 691.952634,517.487954 811.642303,487.59167 C961.489588,450.169049 1051.96243,502.173949 1051.96243,502.173949"></path>
        <path d="M221.870151,388.494896 C221.870151,388.494896 296.819927,316.001633 416.300531,316.001633 C517.697019,316.001633 587.211107,350.810943 612.298898,418.600245 C636.759494,484.769294 669.164557,546.234381 788.854226,516.338097 C938.701511,478.915476 1029.17436,530.920376 1029.17436,530.920376"></path>
        <path d="M199.134341,417.189057 C199.134341,417.189057 274.084116,344.695794 393.56472,344.695794 C494.961209,344.695794 564.475296,379.505104 589.563087,447.294406 C614.023683,513.463454 646.428746,574.928542 766.118416,545.032258 C915.9657,507.609637 1006.43855,559.614537 1006.43855,559.614537"></path>
        <path d="M176.346264,445.935484 C176.346264,445.935484 251.296039,373.442221 370.776644,373.442221 C472.173132,373.442221 541.687219,408.251531 566.77501,476.040833 C591.235606,542.209882 623.64067,603.674969 743.330339,573.778685 C893.177624,536.356064 983.65047,588.360964 983.65047,588.360964"></path>
        <path d="M153.610453,474.629645 C153.610453,474.629645 228.560229,402.136382 348.040833,402.136382 C449.437321,402.136382 518.951409,436.945692 544.0392,504.734994 C568.499796,570.904042 600.904859,632.36913 720.594528,602.472846 C870.441813,565.050225 960.914659,617.055125 960.914659,617.055125"></path>
        <path d="M136.937526,508.445896 C136.937526,508.445896 211.887301,435.952634 331.367905,435.952634 C432.764394,435.952634 502.278481,470.761944 527.366272,538.551245 C551.826868,604.720294 584.231931,666.185382 703.921601,636.289098 C853.768885,598.866476 944.241731,650.871376 944.241731,650.871376"></path>
        <path d="M114.149449,537.140057 C114.149449,537.140057 189.099224,464.646795 308.579829,464.646795 C409.976317,464.646795 479.490404,499.456105 504.578195,567.245406 C529.038791,633.414455 561.443855,694.879543 681.133524,664.983258 C831.033075,627.560637 921.453655,679.565537 921.453655,679.565537"></path>
        <path d="M91.4136382,565.886484 C91.4136382,565.886484 166.363414,493.393222 285.844018,493.393222 C387.240506,493.393222 456.754594,528.202532 481.842385,595.991833 C506.302981,662.160882 538.708044,723.62597 658.397713,693.729686 C808.244998,656.307064 898.717844,708.311964 898.717844,708.311964"></path>
        <path d="M68.6255615,594.580645 C68.6255615,594.580645 143.575337,522.087383 263.055941,522.087383 C364.45243,522.087383 433.966517,556.896693 459.054308,624.685994 C483.514904,690.855043 515.919967,752.320131 635.609637,722.423846 C785.456921,685.001225 875.929767,737.006125 875.929767,737.006125"></path>
        <path d="M45.8897509,623.327072 C45.8897509,623.327072 120.839526,550.83381 240.320131,550.83381 C341.716619,550.83381 411.230706,585.64312 436.318497,653.432421 C460.779094,719.60147 493.184157,781.066558 612.873826,751.170274 C762.721111,713.747652 853.193957,765.752552 853.193957,765.752552"></path>
        <path d="M23.1016742,652.021233 C23.1016742,652.021233 98.0514496,579.527971 217.532054,579.527971 C318.928542,579.527971 388.44263,614.337281 413.530421,682.126582 C437.991017,748.295631 470.39608,809.760719 590.085749,779.864434 C739.933034,742.441813 830.40588,794.446713 830.40588,794.446713"></path>
        <path d="M0.365863618,680.76766 C0.365863618,680.76766 75.315639,608.274398 194.796243,608.274398 C296.192732,608.274398 365.706819,643.083708 390.79461,710.873009 C415.255206,777.042058 447.660269,838.507146 567.349939,808.610862 C717.197223,771.18824 807.670069,823.19314 807.670069,823.19314"></path>
      </g>
    </g>
  </svg>
)

export default Svg
