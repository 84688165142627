import React, { FC, ReactNode, useMemo } from 'react'

import ImageWithBackup from 'components/ImageWithBackup'
import { Photo, PropertyHead } from 'recoil/properties'
import { formatPropertyAddress, formatPublicPropertyAddress } from 'utils/formatting'
import StreetViewImage from 'components/StreetViewImage'
import { getPropertyMapUrl } from 'utils/property'
import DefaultAvatar from 'components/DefaultAvatar'

interface GenericPropertyAvatarProps {
  streetViewLocation: string
  mapboxUrl?: string
  width: number
  height: number
  className?: string
  userPhoto?: Photo
  customDefault?: ReactNode
}

const GenericPropertyAvatar: FC<GenericPropertyAvatarProps> = ({
  streetViewLocation,
  userPhoto,
  mapboxUrl,
  width,
  height,
  className,
  customDefault,
}) => {
  const backup = useMemo(() => {
    if (mapboxUrl) {
      return (
        <ImageWithBackup className={className} primary={mapboxUrl} backup={<DefaultAvatar className={className} />} />
      )
    } else if (customDefault) {
      return customDefault
    } else {
      return <DefaultAvatar className={className} />
    }
  }, [mapboxUrl, customDefault, className])

  if (userPhoto) {
    return <img src={userPhoto.url} alt={userPhoto.name} className={className} />
  } else {
    return (
      <StreetViewImage
        className={className}
        location={streetViewLocation}
        width={width}
        height={height}
        backup={backup}
      />
    )
  }
}

export interface PropertyAvatarProps {
  property: PropertyHead
  mapbox?: boolean
  customDefault?: ReactNode
  width: number
  height: number
  className?: string
}

export const PropertyAvatar: FC<PropertyAvatarProps> = ({
  property,
  width,
  height,
  className,
  customDefault,
  mapbox = false,
}) => {
  const mapboxUrl = mapbox ? getPropertyMapUrl(property, width, height) : undefined
  const streetViewLocation = encodeURIComponent(formatPropertyAddress(property))

  return (
    <GenericPropertyAvatar
      mapboxUrl={mapboxUrl}
      streetViewLocation={streetViewLocation}
      width={width}
      height={height}
      userPhoto={property.avatar}
      className={className}
      customDefault={customDefault}
    />
  )
}

export interface PublicPropertyAvatarProps {
  publicProperty: AttomTaxAssessor
  mapbox?: boolean
  customDefault?: ReactNode
  width: number
  height: number
  className?: string
}

export const PublicPropertyAvatar: FC<PublicPropertyAvatarProps> = ({
  publicProperty,
  width,
  height,
  className,
  customDefault,
}) => {
  const streetViewLocation = encodeURIComponent(formatPublicPropertyAddress(publicProperty))

  return (
    <GenericPropertyAvatar
      streetViewLocation={streetViewLocation}
      width={width}
      height={height}
      className={className}
      customDefault={customDefault}
    />
  )
}
